/**
 * Theme specific typography overrides
 */
/**
 * Theme specific colors
 */
/**
 * Social media colors
 */
#wp-submit {
  border: 1px solid;
  box-shadow: none;
  text-shadow: none;
  padding: 7px 15px;
  font-size: 16px;
  height: auto;
  transition: ease-in 0.3s;
}
#loginform input[type=text],
#loginform input[type=password] {
  border: 0;
  border-bottom: 1px solid;
  box-shadow: none;
  background-color: transparent;
}
#loginform .forgetmenot {
  margin-top: 13px;
}

.login,
#login {
  background-color: #303030;
}
.login p,
.login label,
#login p,
#login label {
  color: !important;
}
.login a,
#login a {
  color: !important;
}
.login h1 a,
#login h1 a {
  background-image: url("../images/logo.png");
  height: 109px;
  width: 180px;
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 30px;
}
.dashicons {
  font-family: dashicons !important;
}

#wp-admin-bar-fw-development .ab-item {
  display: flex !important;
  align-items: center !important;
}
#wp-admin-bar-fw-development .ab-submenu.dashicons {
  margin-right: 3px !important;
}
#wp-admin-bar-fw-development .ab-submenu.dashicons.success {
  color: green;
}
#wp-admin-bar-fw-development .ab-submenu.dashicons.error {
  color: red;
}

.wp-admin .editor-styles-wrapper {
  font-family: "Source Sans Pro";
}
.wp-admin .block-editor .wp-block {
  max-width: 66vw;
}
.wp-admin .edit-post-visual-editor .editor-post-title,
.wp-admin .edit-post-visual-editor .editor-block-list__block {
  max-width: 1100px;
}
.wp-admin .editor-post-title__input {
  margin-bottom: 0.5rem;
  font-family: "Source Sans Pro";
  font-weight: 700;
  line-height: 1.2;
}