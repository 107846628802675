#wp-submit {
  border: 1px solid theme-color('burger-secondary');
  background: theme-color('burger-primary');
  box-shadow: none;
  text-shadow: none;
  padding: 7px 15px;
  font-size: 16px;
  height: auto;
  transition: ease-in .3s;

  &:hover {
    background: theme-color('burger-secondary');
    border-color: theme-color('burger-primary');
    color: theme-color('burger-primary');
  }
}
#loginform {
  background: theme-color('burger-offwhite');

  input[type='text'],
  input[type='password'] {
    background: theme-color('burger-secondary');
    border: 0;
    border-bottom: 1px solid theme-color('burger-third');
    box-shadow: none;
    background-color: transparent;
  }

  .forgetmenot {
    margin-top: 13px;
  }
}

.login,
#login {
  background-color: #303030;
  p,
  label {
    color: theme-color('burger-third') !important;
  }
  a {
    color: theme-color('burger-secondary') !important;
  }
  h1 {
    a {
      background-image: url('../images/logo.png');
      height: 109px;
      width: 180px;
      background-size: contain;
      background-repeat: no-repeat;
      padding-bottom: 30px;
    }
  }
  .message {
    border-left-color: theme-color('burger-primary');
  }
}
