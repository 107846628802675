.dashicons {
  font-family: dashicons !important;
}

#wp-admin-bar-fw-development {
  .ab-item {
    display: flex !important;
    align-items: center !important;
  }
  .ab-submenu.dashicons {
    margin-right: 3px !important;
    &.success {
      color: green;
    }
    &.error {
      color: red;
    }
  }
}