// force gutenberg editor to a larger width so more inputs can be fit on it
.wp-admin {
    .editor-styles-wrapper {
        font-family: $font-family-base;
    }

    .block-editor .wp-block {
        max-width: 66vw;
    }

    .edit-post-visual-editor .editor-post-title,
    .edit-post-visual-editor .editor-block-list__block {
        max-width: 1100px;
    }

    .editor-post-title__input {
        margin-bottom: $headings-margin-bottom;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        color: $headings-color;
    }
}