/**
 * Theme specific typography overrides
 */
$headings-font-family: "Source Sans Pro"; // make all headings Roboto and all heading weights 700
$headings-font-weight: 700;
$font-family-base: "Source Sans Pro"; // make everything that isnt a heading Open Sans
$font-family-serif: "Merriweather";
$fa-font-display: swap;

/**
 * Theme specific colors
 */
$brand-primary: #43569f;
$brand-secondary: #ffffff;
$brand-third: #111111;
$brand-offwhite: #F0F1F1;
$brand-orange: #FAA74A;
$brand-gray: #8596B3;

$darkest-gray: #111111;
$darker-gray: #132230;
$dark-gray: #262626;
$gray: #323232;

$body: #212529;

$brand-primary-hover: #3763AF;

/**
 * Social media colors
 */
$facebook_color: hsla(222, 47%, 40%, 1); // #365397
$twitter_color: hsla(198, 100%, 47%, 1); // #00a9f1
$linkedin_color: hsla(203, 100%, 35%, 1); // #006db3
$apple_color: hsla(0, 0%, 45%, 1); // #737373
$google_color: hsla(217, 89%, 61%, 1); // #4285f4
$google_plus_color: hsla(8, 74%, 53%, 1); // #e0452c
$youtube_color: hsla(0, 100%, 60%, 1); // #ff3333
$vimeo_color: hsla(200, 82%, 61%, 1); // #48b6ed
$pinterest_color: hsla(0, 78%, 45%, 1); // #ce1a19
$yelp_color: hsla(5, 100%, 38%, 1); // #c30f00
$dribbble_color: hsla(338, 82%, 60%, 1); // #ed4584
$amazon_color: hsla(36, 100%, 50%, 1); // #ff9700
$ebay_color: hsla(219, 89%, 30%, 1); // #083790
$skype_color: hsla(198, 100%, 48%, 1); // #00acf4
$instagram_color: hsla(208, 46%, 41%, 1); // #396d9a
$dropbox_color: hsla(206, 89%, 46%, 1); // #0d84de
$flickr_color: hsla(334, 100%, 46%, 1); // #ea0066
$github_color: hsla(0, 0%, 18%, 1); // #2f2f2f
$basecamp_color: hsla(121, 38%, 58%, 1); // #6bbd6d
$tumblr_color: hsla(210, 37%, 30%, 1); // #304c68
$foursquare_color: hsla(206, 72%, 45%, 1); // #207dc5
$box_color: hsla(204, 83%, 40%, 1); // #1277bc

$fa-font-path: "../fonts" !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1200px,
) !default;

$grid-breakpoints: (
        xs: 0,
        sm: 568px,
        md: 768px,
        lg: 1024px,
        xl: 1260px
) !default;
